import React from 'react';
import styled from 'styled-components';
import isObject from 'lodash/isObject';
import GetElementFunc from '../../../util/GetElement';

const Row = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props => props.hide && `
    display: none;
  `}
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-start;
  }
`;

const Label = styled.div`
  color: #424242;
  font-size: 14px;
  font-weight: 400;
  ${props => props.isReview && `
    font-weight: 600;
  `}
  margin: 0;
`;

const Value = styled.div`
  color: #424242;
  font-size: 14px;
  margin: 0;
  padding-left: 75px;
  ${props => props.isReview && `
    font-weight: 400;
  `}
  @media only screen and (max-width: 767px) {
    padding-left: 0;
  }
`;

const FieldSet = ({ fields, isEditMode, sectionIndex, index: listIndex, listParentId, isReview }) => {
  return (
    <>
      {
        fields.map(question => (
          isEditMode ? 
          (
            <GetElementFunc
              questionObj={ question }
              sectionIndex={ sectionIndex }
              required
              isEditMode
              listParentId={ listParentId }
              listIndex={ listIndex }
            />
          )
          : 
          (
            <Row
              name = 'row'
              key = { question.question_id }
              className = { question.className } 
              hide = { question.question_id === 'BeneficiaryPrefill' }
            >
              <Label id = { 'label-' + question.question_id + '-' + listIndex }
                name = 'label' isReview={ isReview }
              >
                {question.question_text}
              </Label>
              <Value label='value' isReview={ isReview }>
                {isObject(question.response) ? question.response.label : question.response}
              </Value>
            </Row>
          )
        ))
      }
    </>
  )
}

export default FieldSet;
/* eslint-disable comma-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import get from 'lodash/get';
import 'crux/dist/index.css'
import { DatePicker as DateSelector, DateInput } from 'crux';
import { updateAnswersArray } from '../redux/actions';
import { getLabelProps, getValidators, getWidth, showPopper, getTooltipProps } from './utils';

const initialState = {
	// adding to make sure api call is done only when date is edited
	isEdited: false,
	isPrefilled: false
}
const DatePicker = ({ question, theme, handleChange, handleBlur, isEditMode, prefillData }) => {
	const [state, setState] = useState(initialState)
	const {
		pageId,
		config: {
			theme: {
				pageOverrides,
				components,
				global: { colorScheme, errorMessageColor, errorFontSize, labelStyles, spanText, inputStyles },
			},
		},
	} = theme;

	const labelProps = getLabelProps(
		'input',
		components,
		pageId,
		pageOverrides,
	);
	const {
		question_id: questionId,
		question_text: questionText,
		response,
		hint_title: tooltipTitle,
		hint_text: tooltipBody,
		hint_html: tooltipHTML,
		tooltip,
		question_status: questionStatus,
		helper_text: helperText,
	} = question;

	const tooltipProps = getTooltipProps(tooltipTitle, tooltipBody, tooltipHTML || tooltip);

	useEffect(() => {
		if (isEditMode || question.response) {
			handleChange(question.response, false, true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if(prefillData) {
			setState({ ...state, value: prefillData, isPrefilled: true })
			handleChange(prefillData, false, true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [prefillData]);

	const validators = getValidators(question);

	// Determines props for Calendar Popper (visible/hidden)
	const hasCalendarPopper = components.datepicker.showPopper
	const showPopperProps = !showPopper(hasCalendarPopper, validators) ?
		{ showCalendarPopper: false, showCalendarIcon: false } :
		{};

	// Checks if guide is enabled or not
	const hasGuide = get(components, 'datepicker.guide', false);

	// overrides floatingLabel to false in case of guide feature enabled
	const floatingLabel = hasGuide ? false : labelProps.floatingLabel;

	let datepicker;

	const commonProps = {
		labelText: questionText,
		id: questionId,
		response: prefillData || response,
		color: colorScheme,
		floatingLabel,
		width: getWidth('datepicker', pageId, pageOverrides),
		guide: hasCalendarPopper ? hasGuide : null,
		yearPlaceholder: !hasCalendarPopper ? 'YYYY' : null,
		monthPlaceholder: !hasCalendarPopper ? 'MM' : null,
		dayPlaceholder: !hasCalendarPopper ? 'DD' : null,
		questionStatus,
		isOptional: questionStatus === 'absent' || questionStatus === 'valid',
		isDatePrefilled: state.isPrefilled,
		...labelProps,
		...tooltipProps,
		...validators,
		helperText,
	}

	const responseFormatLength = validators.responseFormat.length;

	if (hasCalendarPopper) {
		datepicker = (
			<DateSelector
				onChange={ (value, error, isSelectedDate) => { 
					// isSelectedDate - true only if date is selected from calendar
					// value in the argument is always a valid date 
					// even if it is incomplete (e.g. 12/11/2). To prevent accepting incomplete dates
					// it is checked that the raw value is equals or not.
					//
					// CAUTION: only works if the accepted date format is MM/DD/YYYY
					// updating state whenever the date is selected from calendar popup
					if(isSelectedDate && ((prefillData && prefillData !== '') || (!state.value || state.value !== value))){
						setState({
							...state,
							isEdited: false,
							value,
							isPrefilled: false
						})
					}
					handleChange(value, error, true, false, true)
				} }
				onValueChangeRaw={ (value) => {
					setState({
						...state,
						isEdited: true,
						isPrefilled: false,
						value, // the raw input value is stored in state
					})
				} }
				onBlur={ (value, error) => { 
					// value in the argument is always a valid date 
					// even if it is incomplete (e.g. 12/11/2). To prevent accepting incomplete dates
					// it is checked that the raw value is equals or not.
					//
					// CAUTION: only works if the accepted date format is MM/DD/YYYY
					if(typeof value === 'string') {
						if(value.length !== responseFormatLength) {
							setState({ ...state, value: '', isPrefilled: false }) // clear the input
							handleChange('', error, true, false, true) // so that it updates question object
						}
						else{
							handleBlur(value, state.isEdited, error, true)
						}
					}
				} }
				{ ...showPopperProps }
				{ ...commonProps }
				errorMessageColor = { errorMessageColor }
				errorFontSize={ errorFontSize }
				themeConfiguration={ { ...theme } }
				labelStyles={ labelStyles }
				spanText={ spanText }
				inputStyles={ inputStyles }
				value={ state.value } // bind the input with state
			/>
		)
	} else {
		datepicker = (
			<DateInput
				{ ...commonProps }
				onChange={ (value, error) => { handleChange(value, error, true) } }
				errorMessageColor = { errorMessageColor }
			/>
		)
	}

	return datepicker;
};

DatePicker.defaultProps = {
	sectionIndex: -1,
};
DatePicker.propTypes = {
	/* eslint-disable react/forbid-prop-types */
	question: PropTypes.object.isRequired,
	/* eslint-disable react/no-unused-prop-types */
	sectionIndex: PropTypes.number,
	handleChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
	const questionId = get(props, 'question.question_id', '');
	const memberDataIndex = get(state, 'questions.memberDataIndex');
	const memberData = get(state, 'questions.otherParams.supplmentalresponse.member_data', []);
	if(memberData.length > parseInt(memberDataIndex, 10)) {
		const member = memberData[memberDataIndex];
		if(member && questionId === 'BeneDateOfBirth') {
			const memberKey = 'birthdate';
			const memberValue = member[memberKey];
			if(memberValue) {
				return {
					prefillData: memberValue,
				}
			}
		}
	}
	return {};
}

const mapDispatchToProps = (dispatch, props) => {
	const questionId = get(props, 'question.question_id', '');
	// question_status='absent' means the question is optional and for date input
	// if the date is null we don't consider it as error if DOB is an optional question
	// and there is no error in the date entered. This case is seen in the beneficiary 
	// section of Nationwide where DOB is optional and date can be null
	// const questionStatus = get(props, 'question.question_status', '');
	// let error;
	return {
		handleChange: (
			response,
			hasError,
			preventReflexiveCall,
			ignoreChildQuestionsOn,
			isChangeEvent
		) => {
			dispatch(
				updateAnswersArray({
					questionId,
					response,
					sectionIndex: props.sectionIndex,
					preventReflexiveCall,
					isError: hasError.error,
					ignoreChildQuestionsOn,
					listParentId: props.listParentId,
					listIndex: props.listIndex,
					// validate: get(props, 'question.dob_valid_flag', false),
					isChangeEvent
				}),
			);
		},
		handleBlur: (response, isEdited, hasError, isChangeEvent) => {
			dispatch(
				updateAnswersArray({
					questionId,
					response,
					preventReflexiveCall: isEdited,
					sectionIndex: props.sectionIndex,
					isError: hasError && hasError.error,
					validate: get(props, 'question.dob_valid_flag', false) && isEdited,
					isChangeEvent
				}),
			);
		},
	};
};

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(DatePicker));

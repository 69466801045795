import 'core-js';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';
import '../node_modules/babel-polyfill';
import * as serviceWorker from './serviceWorker';
import log from './logger';
import { showLandingPage } from './util/getLandingPage';

log();
showLandingPage();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

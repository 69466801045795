import get from 'lodash/get';
import isArray from 'lodash/isArray';
import intersection from 'lodash/intersection';
import omit from 'lodash/omit';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import { axiosInstance } from '../../util/axios';
import { 
	  UPDATE_QUESTION_ANSWER, 
	  UPDATE_DATA_SELECTOR_OPTIONS,
      GET_NEW_QUESTION_START, 
      SET_CURRENT_BREADCRUMB, 
	  GET_QUESTION_FAILURE,
	  SET_ACTION_SUCCESS_MESSAGE,
	  SET_ACTION_FAILURE_MESSAGE,
	  TOGGLE_ESIGN_BUTTON,
	  SET_POPUP_DETAILS,
	  SET_AGENT_PARAMETERS,
	  SET_SINGLE_SELECT_FOCUS,
	  SET_CSRF_TOKEN
} from '../types';
import { setUid, setCJEventId } from './configActions';
import router from '../../util/router';
import { getClubCode } from '../../util/index';
import { getRefreshToken, getJWTAuthToken } from './authHandler';
import prepareQuestionsPayload from '../../util/questionFormatter';

const getCookieData = (name) => {
	const nameEQ = `${name  }=`;
    const ca = document.cookie.split(';');
    for(let i=0; i<ca.length; i+=1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

const dispatchActionsForQuestions = (dispatch, response, params) => {

	// Redirection Logic
	const displayType = get(response, 'data.response.data.questionnarie.questions.display_type', '');
	if(displayType === 'redirection') {
		const redirectionUrl = get(response, 'data.response.data.questionnarie.questions.redirection_url', '');
		window.location.replace(redirectionUrl);
		return;
	}

	const uid = get(response, 'data.response.other_params.uid', '');
	const cjEventId = get ( response, 'data.response.other_params.cjevent_id')
	const cookieData = getCookieData('cje')
	const currentStatus = get( response, 'data.response.other_params.current_status', '').toUpperCase();
	const { 
		agent_name: agentName,
		ALAN_Number: alanNumber,
		agent_phone: agentPhone,
		agent_code: agentCode
	} = get(response, 'data.response.other_params', {})

	dispatch({
		type: SET_CSRF_TOKEN,
		csrfToken: response.data.csrfToken
	});
	if(cjEventId) {
		router(dispatch, uid, cjEventId, response);
		dispatch(setCJEventId(cjEventId ));
	} else {
		router(dispatch, uid, cookieData, response);
		dispatch(setCJEventId(cookieData));
	}
	dispatch(setUid(uid));
	dispatch({
		type: `GET_${currentStatus}_SUCCESS`,
		payload: get(response, 'data.response', null),
		params,
	});
	if (!isEmpty(params.actionProps)) {	
		dispatch({
			type: SET_ACTION_SUCCESS_MESSAGE,
			payload: {
				actionMessage: {
					type: 'success',
					message: get(params, 'actionProps.success.message', null),
				},
			},
		})
	}
	dispatch({ 
		type: SET_AGENT_PARAMETERS,
		payload: { agentName, agentCode, agentPhone, alanNumber }
	})
	if(params.breadcrumbNavigation){
		dispatch(setCurrentBreadcrumb(params.breadcrumb_id));
	}
}

export const getNextQuestionSet = (
	url,
	formData,
	params = {
		isReflexive: false,
		sectionIndex: 0,
		validate: false,
		isListAddAction: false,
		reload: false,
		actionProps: {},
		breadcrumbNavigation: false,
		actionMessage: { type: '', message: '' },
		reset_sections_data: false
	},
) => {
	return (dispatch, getState) => {
		const state = getState();
		const queryUid = get(state, 'router.location.query.uid', '');
		const cje = get(state, 'config.cje', '')
		const { clubCode } = state;
		const { agentParams } = state
    	const updatedFormData = queryUid
    	? { ...formData, uid: queryUid, cje, clubCode, agent_code: agentParams?.agentCode }
    	: { ...formData, clubCode, agent_code:agentParams?.agentCode };

		if(window.location.pathname.includes("Enroll")) {
			updatedFormData.visited = true;
		}
		dispatch({
			type: GET_NEW_QUESTION_START,
			updatedFormData,
			params,
		});
		if(window.navigator.onLine){
			axiosInstance
			.post(url, updatedFormData)
			.then((response) => {
				if(response.data.errors){
					setErrorMessage(response.data.errors, dispatch)
				}
				else{
					dispatchActionsForQuestions(dispatch, response, params)
				}
			})
			.catch(async(error) => {
				console.error(error);
				setCSRFTokenValue(error, dispatch);
				const refreshRetry = get(error, 'response.config._retry', false);
				if (refreshRetry) {
					const originalRequest = error.config;
					console.debug('original request recieved is ', originalRequest);
					const accessToken = await dispatch(getRefreshToken());
					console.debug('recieved accesstoken for', originalRequest ,' is ', accessToken);
					if (accessToken) {
						axiosInstance
						.post(originalRequest.url, JSON.parse(originalRequest.data))
						.then((response) => { dispatchActionsForQuestions(dispatch, response, params) })
					}
				}
				else {
					setErrorMessage(error, dispatch)
				}
			})
		}
		else {
			setErrorMessage({message: 'Network Error - Internet disconnected'}, dispatch)
		}
	};
}

const setErrorMessage = (error, dispatch) => {
	let errorMessage = ''
	if(error.message && error.message.includes('Network Error')){
		errorMessage = error.message
	}
	else if(error.response?.data?.errors){
		let errors = error.response.data.errors
		// Fetch userMessage message from errors object if errors is not of type string.
		if (typeof errors !== 'string') {
			errorMessage = errors.userMessage || 'Some Error Occured!';
		}
	}
	else if(error.userMessage){
		errorMessage = error.userMessage
	}
	dispatch({
		type: GET_QUESTION_FAILURE,
	});
	dispatch({
		type: SET_ACTION_FAILURE_MESSAGE,
		payload: {
			actionMessage: {
				type: 'error',
				message: errorMessage,
			},
		},
	});
}

const setCSRFTokenValue = (error, dispatch) => {
	let csrfToken = "";
	if(error.response){
		if(error.response.data && error.response.data.constructor === ({}).constructor){ // get csrftoken only is error.response.data is object
			csrfToken = error.response.data.csrfToken;
		}
		else csrfToken = error.response.csrfToken;
	}
	else csrfToken = error.csrfToken;
	dispatch({
		type: SET_CSRF_TOKEN,
		csrfToken: csrfToken
	});
}

export const getAuthQuestionSet = (response) => {
	return (dispatch) => {
		const currentStatus = get(response, 'data.response.other_params.current_status', '').toUpperCase();
		router(dispatch, null, false, response);
		dispatch({
			type: `GET_${currentStatus}_SUCCESS`,
			payload: get(response, 'data.response', null),
			params: {},
		});
	}
}

export const getSectionAnswers = (currentBreadcrumb)=>{
	const answersArray = []
	currentBreadcrumb.forEach(section => {
		section.answersArray.forEach(answer => (answer.answer || answer.answer === 0) && answersArray.push(answer) )
	});
	let questionList = currentBreadcrumb.reduce((acc, section) => { return [...acc, ...section.questionList] }, [])
	questionList = questionList.filter(question => question.presentation_type !== 'review')
	return { answersArray, questionList }
}

export const getAnsweredListQuestion = (currentBreadcrumb) => {
	const answeredListQuestion = currentBreadcrumb.reduce((acc, section)=> {
		return [...acc, ...section.answeredListQuestion]
	}, [])
	return answeredListQuestion;
}

const formatAuthAnswers = (answersArray) => {
	const reducer = (accumulator, currentValue) => {
		accumulator[currentValue.question_id] = currentValue.answer;
		return accumulator;
	  }
	return answersArray.reduce(reducer, {});
}

export const submitAuthQuestion = () => {
	return (dispatch, getState) => {
		const state = getState();
		const { questions } = state;
		const { activeSubsectionId } = questions;
		const currentSection = questions.sections[activeSubsectionId];
		const  { answersArray } = getSectionAnswers(currentSection);
		
		const destinationURL = get(state, 'auth.destinationURL', '/');
		const loginURL = get(state, 'auth.login.loginURL', '/auth');
		let reqParams;
		if (!isEmpty(answersArray)) {
			reqParams = formatAuthAnswers(answersArray);
		}
		dispatch(
			getJWTAuthToken(loginURL, reqParams, {
				redirectTo: destinationURL,
			}),
		);
	}
}

const insertMissingAnswers = (answersArray, questionList, isReflexive) => {
	return questionList.reduce((acc, question) => {
		const alreadyAnsweredQuestionObject = answersArray.filter(item => item.question_id === question.question_id);
		if (question.display_type === 'breadcrumb' || (question.display_type === 'list' && question.submission_type === 'all')) {
			return acc;
		}
		if (alreadyAnsweredQuestionObject.length) {
			acc.push(...alreadyAnsweredQuestionObject);
			return acc;
		}
		if (question.question_id && question.display_type) {
			if (!isReflexive){
				acc.push({
					question_id: question.question_id,
					answer: '',
				});
		}
		}
		return acc;
	}, []);
};

const appendListCompletionFlag = (questions, answeredListQuestion) => {
	answeredListQuestion.forEach((qId) => {
		if(!questions.find(question => question.question_id === qId)) {
			questions.push({
				question_id: qId,
				answer:[],
			})
		}
		return null;
	})
	const appended = questions.map(question=> {
		if(answeredListQuestion.indexOf(question.question_id) > -1){
			return {
				...question,
				answer:[],
				extra_params: {
          			list_completed_flag: true,
        		},
			}
		}
		return question;
	})
	return appended;
}

const getNListAnswers = (currentSubsection) => {
	const { answersArray } = currentSubsection;
	return answersArray.filter(a => a.question_id.match(/_\$ureify_/));
}

const formatListQuestions = (questions) => {
	const listQs = questions.filter(q => q.listParentId);
	const otherQs = questions.filter(q => !q.listParentId);
	const extraParams = { list_completed_flag: false };

	if (listQs && listQs.length) {
		const { listParentId, listIndex } = listQs[0];
		const answer = [];
		if (Number.isInteger(listIndex) && listIndex >= 0) {
			extraParams.current_list_answer_index = listIndex;
		}
		listQs.forEach(q => {
			answer.push({
				question_id: q.question_id,
				answer: q.answer
			});
		})
		return [
			{
				question_id: listParentId,
				answer,
				extra_params: extraParams,
			},
			...otherQs,
		]
	}
	return questions;
}

export const submitSectionQuestion = (
	sectionIndex,
	otherParams = {
		isReflexive: false,
		sectionIndex: 0,
		validate: false,
		actionProps: {},
		removeIndex: {},
		reset_sections_data: false
	},
	action= '',
	url = '/questions',
) => {
  return (dispatch, getState) => {
    const state = getState();
		const { questions, uid } = state;
		const { activeSubsectionId } = questions;
		const pageSeqNumber = get(questions, 'currentPageSeqNumber', 1);
		const currentSection = questions.sections[activeSubsectionId];
		const currentSubsection = currentSection[sectionIndex];
		const { completed, questionList: currentQuestionList } = currentSubsection;
		let allQuestions = [];

		if (otherParams.isReflexive || otherParams.validate) {
			const { answersArray } = getSectionAnswers(currentSection);
			allQuestions =  answersArray;
		} else {
			const  { answersArray, questionList } = getSectionAnswers(currentSection);
			allQuestions = [
				...insertMissingAnswers(answersArray, questionList),
				...getNListAnswers(currentSubsection),
			]
		}

		let listQId = '';
		let listIdx = null;
		const listQ = allQuestions.filter(q => q.listParentId)
		const isListAddAction = !!listQ.length;
		const isListEditAction = !!listQ.filter(q => Number.isInteger(q.listIndex) && q.listIndex >= 0).length;
		if (listQ.length) {
			const { listParentId, listIndex } = listQ[0];
			listQId = listParentId;
			listIdx = listIndex;
		}

		// Don't add list_completed_flag in case of list edit action
		if (!isListEditAction) {
			const answeredListQuestion = getAnsweredListQuestion(currentSection);
			allQuestions = appendListCompletionFlag(allQuestions, answeredListQuestion)
		}
		allQuestions = formatListQuestions(allQuestions);

		const payload = prepareQuestionsPayload(allQuestions, state.meta.listRelation[activeSubsectionId], otherParams.removeIndex);
		let reqParams = {
			uid,
			page_sequence_number: pageSeqNumber,
			questions: payload,
			...(completed ? {index_modify_flag: 1} : {next_page_flag: 1}),
			...(otherParams.isReflexive && {index_modify_flag: undefined, next_page_flag: undefined, reflexive_question_flag: 1}),
			...(otherParams.validate && {index_modify_flag: undefined, next_page_flag: undefined, dob_validation_flag: 1}),
			...(action && {index_modify_flag: undefined, next_page_flag: undefined, action}),
			...(activeSubsectionId === 'quotereview' && {next_page_flag: 1, index_modify_flag: 1}),
			...(isListEditAction && {index_modify_flag: 1, list_question_id: listQId}),
			...(!isEmpty(otherParams.actionProps) && {index_modify_flag: undefined, next_page_flag: undefined, ...otherParams.actionProps.body})
		};
		
		if (action === 'CANCEL_UPDATE') { reqParams = omit(reqParams, 'questions'); }
		
		dispatch(getNextQuestionSet(url, reqParams, {
		  ...otherParams,
		  sectionIndex,
		  sectionId: get(currentQuestionList[0],'question_id',''),
		  isListAddAction,
		  ...reqParams,
		}));

		if (otherParams.isReflexive && isListEditAction) {
			dispatch({
				type: 'SET_REFLEXIVE_LIST_INDEX',
				index: listIdx,
				id: listQId,
			});
		}
	};
};
   
export const setCurrentBreadcrumb = (id) => ({
  type: SET_CURRENT_BREADCRUMB,
  id,
})

export const toggleESignButton = (enable) => ({
  type: TOGGLE_ESIGN_BUTTON,
  enable,
})


const answerIsAMatch = (allAnswers, currentAnswer) => {
  if (isArray(currentAnswer)) {
    return intersection(allAnswers, currentAnswer).length!==0;
  }
  let answer = currentAnswer

  if (isObject(currentAnswer)) {
    answer = currentAnswer.id;
  } else if (!Number.isNaN(Number.parseFloat(answer))) {
    answer = Number.parseFloat(currentAnswer);
  }

  answer = allAnswers.filter(a => {
    try {
      if (isObject(currentAnswer)) {
        return (a.toString() === (currentAnswer.id).toString())
      }
      return a.toString() === currentAnswer.toString()
    } catch (e) {
      console.error('Error in filter', e)
      return false
    }
  }).length
  return answer;
}

/**
 * @description function determines if reflexive question API call
 * should be done.
 * @param {Array} questionsArray existing questions and answers
 * @param {Array} childQuestionsOn options for which child questions
 * will be sent
 * @param {String} currentQuestionId
 * @param {String} currentAnswer
 * @return {Boolean}
 */
export const shouldMakeReflexiveCall = (
	questionsArray,
	childQuestionsOn,
	currentQuestionId,
	currentAnswer,
	ignoreChildQuestionsOn,
) => {
	const hasAnswer = questionsArray.filter(question => question.question_id === currentQuestionId)[0];
	const response = hasAnswer ? hasAnswer.response : undefined;

	if(response && ignoreChildQuestionsOn){
		return true;
	}

	if (childQuestionsOn && answerIsAMatch(childQuestionsOn, currentAnswer)) {
		return true;
	}

	// if there are no answer eligible for reflexive calls, return false.
	if (!childQuestionsOn || (isArray(childQuestionsOn) && childQuestionsOn.length === 0)) {
		return false;
	}

	/**
	 * if there is no answer and the current answer is not part
	 * of child_questions_on array, do not make the api call.
	 * For every other condition, make the api call.
	 */
	if (!response && !answerIsAMatch(childQuestionsOn, currentAnswer)) {
		return false;
	}

	/**
	 * if we have an answer and this answer is not in the
	 * child_question_on array, AND
	 * if the new answer is also not in the child_questions_on
	 * array then do not make the api call.
	 */
	return !(response && answerIsAMatch(childQuestionsOn, hasAnswer.response) && answerIsAMatch(childQuestionsOn, currentAnswer));
};

export const updateAnswersArray = (
	payload = {
		sectionIndex: 0,
		response: '',
		questionId: '',
		questionResponse: {},
		preventReflexiveCall: false,
		isError: false,
		validate: false,
		ignoreChildQuestionsOn: false,
		propQuestion: null,
		listParentId: '',
		listIndex: null,
		isChangeEvent: false
	},
) => {
	return (dispatch, getState) => {
		if (
			payload.response ||
			payload.isError ||
			payload.response === ''
		) {
			const currentState = getState();
			const {
				breadcrumbs,
				activeSubsectionId,
				currentPageSeqNumber,
				eSign,
			} = currentState.questions;
			const currentBreadcrumbId = get(breadcrumbs, 'currentBreadcrumb.id', '');
			const {
				sectionIndex,
				response,
				questionId,
				preventReflexiveCall,
				validate,
				ignoreChildQuestionsOn,
				propQuestion,
				listParentId,
				listIndex,
				isChangeEvent
			} = payload;

			const currentSubSection = currentState.questions.sections[activeSubsectionId][sectionIndex];
			const { questionList } = currentSubSection;

			if (questionId.indexOf('_$ureify_') > -1) {
				dispatch({
					type: UPDATE_QUESTION_ANSWER,
					currentBreadcrumbId,
					currentPageSeqNumber,
					payload: {
						...payload,
						questionId,
						propQuestion,
						eSign,
						isChangeEvent
					},
					activeSubsectionId,
				});
				if (!preventReflexiveCall && propQuestion.child_questions) {
					dispatch(submitSectionQuestion(sectionIndex, {
						isReflexive: true,
						sectionIndex,
						preventReflexiveCall: true,
					}))	
				}
				return;
			}

			let question;
			let questionsArray = questionList
			if (listParentId && Number.isInteger(listIndex) && listIndex >= 0) {
				const listParentQ = questionList.filter(q => q.question_id === listParentId)[0];
				const listQ = listParentQ.questions[listIndex];
				[question] = Object.values(listQ).filter(q => q && q.question_id === questionId);
			} else if (listParentId) {
				const listParentQ = questionList.filter(q => q.question_id === listParentId)[0];
				const listQ = listParentQ.original_questions ? 
					listParentQ.original_questions : listParentQ.questions;
				[question] = Object.values(listQ).filter(q => q && q.question_id === questionId);
				questionsArray = listQ;
			} else {
				[question] = questionList.filter((q) => q.question_id === questionId);
			}
			
			const parentId = get(question, 'properties.parent_group_id', '');
			const parentQuestion = questionList.filter((q) => q.question_id === parentId)[0];
			
			let isDataSelector = false;
			const currentQuestionSet = get(parentQuestion, 'questions', []);
            if(currentQuestionSet.length > 0) {
			   isDataSelector = currentQuestionSet[0].display_type === 'data_selector';
			}

			let reflexCall = false;
			if (!preventReflexiveCall && question && question.child_questions) {
				reflexCall = shouldMakeReflexiveCall(
					questionsArray,
					question.child_questions_on,
					questionId,
					response,
					ignoreChildQuestionsOn,
				);
			}
			dispatch({
				type: UPDATE_QUESTION_ANSWER,
				currentBreadcrumbId,
				currentPageSeqNumber,
				payload: {
					...payload,
					eSign,
					isChangeEvent
				},
				activeSubsectionId,
			});

			if(isDataSelector){
				dispatch({
					type: UPDATE_DATA_SELECTOR_OPTIONS,
					currentBreadcrumbId,
					currentPageSeqNumber,
					 payload: {
						 ...payload,
						 questionId,
						 parentQuestion,
					 },
					 activeSubsectionId,
				})
			}

			// check if reflexive answer
			if (!preventReflexiveCall) {
				if (reflexCall) {
				dispatch(submitSectionQuestion(sectionIndex, { 
					isReflexive: true,
					sectionIndex,
					preventReflexiveCall: true,
				}));
				}
			}
			if (validate && response) {
				dispatch(
					submitSectionQuestion(sectionIndex, {
						validate: true,
						sectionIndex,
						preventReflexiveCall: true,
					}),
				);
			}
		}
	};
};

export const makeQuestionsCall = () => {
	return (dispatch, getState) => {
		const currentState = getState();
		const { uid } = currentState;
		const {
			breadcrumbs,
			currentPageSeqNumber,
			breadcrumbIndex,
		} = currentState.questions;
		const { breadcrumbList } = breadcrumbs;
		const currentBreadcrumbIndex = breadcrumbIndex - 1;
		if (currentBreadcrumbIndex > -1 && breadcrumbList[currentBreadcrumbIndex].clickable) {
			const currentBreadcrumb = breadcrumbList[currentBreadcrumbIndex];
		 	const params = {
				uid,
				breadcrumb_id: currentBreadcrumb.breadcrumb_id,
				breadcrumb_nav_flag: true,
				page_sequence_number: currentPageSeqNumber,
			};
			dispatch(setCurrentBreadcrumb(currentBreadcrumb.breadcrumb_id));
        	dispatch(getNextQuestionSet('/questions', params));
		}
	};
};

export const getNextPage = (params) => {
	return (dispatch, getState) => {
		const { uid, questions } = getState();
		const { currentPageSeqNumber } = questions;

		const reqParams = {
			uid,
			page_sequence_number: currentPageSeqNumber,
			...params,
		}

		dispatch(getNextQuestionSet('/questions', reqParams, {
			...reqParams,
		}));
	}
}

export const goToPreviousBreadCrumb =(sectionIndex)=>{
    return (dispatch, getState) => {
      const currentState = getState()
      const { currentPageSeqNumber } = currentState.questions;
      const { uid } = currentState;
      const reqParams = {
        uid,
        page_sequence_number:currentPageSeqNumber,
        prev_page_flag: true,
      }
      dispatch(getNextQuestionSet('/questions', reqParams, {
        ...reqParams,
        sectionIndex,
      }, reqParams));
    }
  }

export const setPopupDetails = (payload) => ({
	type: SET_POPUP_DETAILS,
	payload,
});

export const setSingleSelectFocus = (payload) => {
	return (dispatch) => {
		dispatch({
			type: SET_SINGLE_SELECT_FOCUS,
			shouldFocusSingleSelect: {
				id: payload.id,
				value: true
			}
		});
	};
}
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import styled from 'styled-components';
import ReflexiveCard from '../UIElements/Card/ReflexiveCard';
import GetElementFunc from '../../util/GetElement';
import { questionType } from '../../types';
import { rebuildQuestionList } from '../../utils';
import ListQuestions from '../List';
import NList from '../../n-list';
import { setListRelation } from '../../redux/actions/meta';
import TooltipModal from '../../cruxComponents/utils/TooltipModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const isRequired = (question) => {
  return get(question, 'validations.required.value', false);
};

const skipUpdate = (currentRelatives, question) => {
	if (!currentRelatives) {
		return false;
	}
	let hasMatch = false;
	const pId = get(question, 'properties.parent_list_id', '');
	const qId = question.question_id.split('_$ureify_')[1];
	const parents = Object.keys(currentRelatives);
	for (let i = 0; i < parents.length; i += 1) {
		if (parents[i] === qId && currentRelatives[parents[i]] === pId) {
      		hasMatch = true;
      		break;
	    }
  	}
  	return hasMatch;
}

const questionRenderer = (
	questionList,
	sectionIndex,
	reflexLevel,
	overrideReflexLevel,
	changeMode,
	updateListRelation,
	activeSubsectionId,
	currentRelatives,
) => {
	return questionList.map((item, index) => {
		if (item.submission_type === 'all' && item.display_type === 'list') {
			return <NList listQ={ item }/>
		}
		if (isArray(item)) {
			return (
				<ReflexiveCard
					key={ `${sectionIndex}--${reflexLevel + 1}--${index}` }
					questionArray={ item }
					reflexLevel={ overrideReflexLevel ? 0 : (reflexLevel + 1) }
					view={ get(item[0], 'properties.view', '') }
					hideWrapper={ get(item[0], 'properties.hideReflexWrapper', false) }
				>
					{questionRenderer(
						item,
						sectionIndex,
						reflexLevel + 1,
						overrideReflexLevel,
						changeMode,
						updateListRelation,
						activeSubsectionId,
						currentRelatives
					)}
				</ReflexiveCard>
			);
		}
		if(item.display_type === 'list') {
			return (
				<ListQuestions
			    	key={ item.question_id }
					sectionIndex={ sectionIndex }
					questionObj={ item }
					reflexLevel={ reflexLevel }
					listId={ item.question_id }
					listAddButtonLabel={ item.list_add_button_label }
					changeMode={ changeMode }
					originalQuestions={ item.questions.length ? item.questions : item.original_questions }
					listDisplay={ item.child_questions_completed_flag }
			    	isReview = { overrideReflexLevel }
				/>
			)
		}
		if (!item.display_type) {
			let nestedItems = Object.values(item);
			nestedItems = nestedItems.filter(i => i.display_type)
			if(nestedItems.length) {
				return (
					<ReflexiveCard
						key={ `benf--${sectionIndex}--${reflexLevel + 1}--${index}` }
						questionArray={ nestedItems }
						reflexLevel={ reflexLevel + 1 }
					>
						{questionRenderer(
							Object.values(nestedItems),
							sectionIndex,
							reflexLevel + 1,
							changeMode,
							updateListRelation,
							activeSubsectionId,
							currentRelatives
						)}
					</ReflexiveCard>
				)
			}
		}
		if(!item.is_hidden) {
			if (item.question_id.indexOf('_$ureify_') > -1 && !skipUpdate(currentRelatives, item)) {
				updateListRelation(item, activeSubsectionId);
			}
			return (
				<Fragment key={ `${sectionIndex}--${reflexLevel + 1}--${index}` }>
					<GetElementFunc
						questionObj={ item }
						sectionIndex={ sectionIndex }
						required={ isRequired(item) }
					/>
				</Fragment>
			);
		}
		return null;
	});
};

const QuestionRenderer = ({ 
	questionList,
	sectionIndex = 0,
	pageId,
	rebuild,
	updateListRelation,
	activeSubsectionId,
	changeMode,
	currentRelatives,
	popupDetails
}) => {
  if (questionList.length) {
		if (rebuild === undefined || rebuild === true) {
			const updatedQuestionList = rebuildQuestionList(
				[...cloneDeep(questionList)],
				questionList[0].reflexive_index,
			);
			const overrideReflexLevel = pageId === 'quotereview' || pageId === 'review';
			/**
			 * Group the inline questions if
			 * Orientation is row
			 * Orientation value is greater than 1
			 */
			const orientationType = get(updatedQuestionList[0], 'properties.orientation.type', '');
			const orientationValue = get(updatedQuestionList[0], 'properties.orientation.value', 1);
			const elements = [];
			let index = 0;
			if( orientationType === 'rows' && orientationValue > 1) {
				while(index < updatedQuestionList.length){
					const isInline = get(updatedQuestionList[index], 'properties.inline.value', false);
					let endIndex = index+1;
					if(isInline){
						endIndex = index+orientationValue;
					}
					elements.push(
						<Wrapper>
							{questionRenderer(
								updatedQuestionList.slice(index, endIndex),
								sectionIndex,
								0,
								overrideReflexLevel,
								changeMode
							)}
						</Wrapper>
					);
					index = endIndex;
				}
				return <>{elements}</>;
			}
			return (<>
				{questionRenderer(
					updatedQuestionList,
					sectionIndex,
					0,
					overrideReflexLevel,
					changeMode,
					updateListRelation,
					activeSubsectionId,
					currentRelatives
				)}
				{popupDetails.show && <TooltipModal />}
			</>);
		}
		return (<>
			{questionRenderer(
				questionList,
				sectionIndex,
				0,
				false,
				changeMode,
				updateListRelation,
				activeSubsectionId,
				currentRelatives
			)}
			{popupDetails.show && <TooltipModal />}
		</>);
  }
  return null;
};

QuestionRenderer.propTypes = {
  questionList: PropTypes.arrayOf(questionType).isRequired,
  sectionIndex: PropTypes.number.isRequired,
	pageId: PropTypes.string.isRequired,
};

export default connect(({ questions, meta }) => {
	return {
		activeSubsectionId: questions.activeSubsectionId,
		currentRelatives: meta.listRelation[questions.activeSubsectionId],
		popupDetails: get(questions, 'popupDetails', {}),
	}
}, (dispatch) => (
	{
		updateListRelation: (question, activeSubsectionId)  => {
			dispatch(setListRelation({
				question,
				activeSubsectionId,
			}));
		},
	}
))(QuestionRenderer);

// eslint-disable-next-line import/no-cycle
import React from 'react';
import { get } from 'lodash'
import { connect } from 'react-redux';
import { ReviewLabel } from '../Components/UIElements';
import TextElement from '../Components/UIElements/TextElement';
import Pdf from '../Components/UIElements/Pdf';
import Input from '../cruxComponents/Input';
import MultiSelect from '../cruxComponents/MultiSelect';
import SingleSelect from '../cruxComponents/SingleSelect';
import SelectOption from '../cruxComponents/SelectOption';
import DatePicker from '../cruxComponents/DatePicker';
import Modal from '../Components/UIElements/Modal';
import DataSelector from '../cruxComponents/DataSelector';
import GroupQuestionText from 
    '../Components/UIElements/TextElement/GroupQuestionText';
import '../Components/QuestionRenderer/style.css'
import { getPopupQuestions } from '../redux/selector/validations';
import { setPopupDetails } from '../redux/actions/questions';
import { getPopupDetails } from '../utils';
/**
 * @description create an extra layer of div to add (if needed) any attribute
 * to any of the form elements. The reason of creating this wrapper is to have
 * a parent element which can take care of all the instances of execution of
 * GetElementFunc.
 */
const getElement = ({
    questionObj,
    sectionIndex,
    isEditMode = false,
    listParentId = '',
    listIndex = null,
    popupQuestions, 
    updatePopupDetails,
},
) => {
    const handlePopup = (e) => {
        // the preventDefault will prevent toggling of checkbox when label is clicked
        e.preventDefault();
        if(popupQuestions && updatePopupDetails) {
          const newPopupDetails = getPopupDetails(questionObj, popupQuestions);
          updatePopupDetails(newPopupDetails);
        }
    };
    const presentationType = questionObj.presentation_type || 'questions';
    if (presentationType === 'review' || questionObj.display_type === 'html') {
        return (
            <ReviewLabel
                questionObj={ questionObj }
                key={ questionObj.question_id }
                handleLabelChange={ () => {} }
            />
        );
    }
    switch (questionObj.question_type) {
        case 'text':
        case 'password':
        case 'number':
        case 'text_area':
            return (
                <Input
                    question={ questionObj }
                    key={ questionObj.question_id }
                    sectionIndex={ sectionIndex }
                    isEditMode={ isEditMode }
                    listParentId={ listParentId }
                    listIndex={ listIndex }
                />
            );
        case 'date':
            return (
                <DatePicker
                    question={ questionObj }
                    key={ questionObj.question_id }
                    sectionIndex={ sectionIndex }
                    isEditMode={ isEditMode }
                    listParentId={ listParentId }
                    listIndex={ listIndex }
                />
            );
        case 'singleselection':
            if (questionObj.display_type && (
                questionObj.display_type === 'radio'||
                questionObj.display_type === 'radio_button' ||
                questionObj.display_type === 'cards' ||
                questionObj.display_type === 'product_card')
            ) {
                return (
                    <SingleSelect
                        question={ questionObj }
                        key={ questionObj.id }
                        sectionIndex={ sectionIndex }
                        isEditMode={ isEditMode }
                        listParentId={ listParentId }
                        listIndex={ listIndex }
                    />
                );
            }
            if (questionObj.display_type && questionObj.display_type === 'data_selector') {
                return <DataSelector question={ questionObj } sectionIndex={ sectionIndex } />
            }
            if (questionObj.display_type && questionObj.display_type === 'modal') {
                return (
                    <Modal
                        key={ questionObj.question_id }
                        questionObj={ questionObj }
                        response={ questionObj.response }
                        sectionIndex={ sectionIndex }
                    />
                );
            }
            return (
                <SelectOption
                    question={ questionObj }
                    key={ questionObj.question_id }
                    sectionIndex={ sectionIndex }
                    isEditMode={ isEditMode }
                    listParentId={ listParentId }
                    listIndex={ listIndex }
                    type="D"
                />
            );
        case 'multiselection':
            if (questionObj.display_type === 'checkbox') {
                return (
                    <MultiSelect
                        question={ questionObj }
                        sectionIndex={ sectionIndex }
                        handlePopup={ handlePopup }
                    />
                );
            }
            return (
                <SelectOption
                    question={ questionObj }
                    key={ questionObj.question_id }
                    sectionIndex={ sectionIndex }
                    isEditMode={ isEditMode }
                    listParentId={ listParentId }
                    listIndex={ listIndex }
                    type="M"
                />
            );
        case 'autocomplete-text':
            return (
                <SelectOption
                    question={ questionObj }
                    key={ questionObj.question_id }
                    sectionIndex={ sectionIndex }
                    type="A"
                />
            );
        case 'document':
            return (
                <Pdf 
                    question={ questionObj }
                    key={ questionObj.question_id }
                    sectionIndex={ sectionIndex }
                />
            )
        case 'group':
            return (
                <GroupQuestionText
                    key={ questionObj.question_id }
                    questionObj={ questionObj }
                    handlePopup={ handlePopup }
                />
            );
        case 'label':
        default:
           if (questionObj.display_type && questionObj.display_type === 'modal') {
                return (
                    <Modal
                        key={ questionObj.question_id }
                        questionObj={ questionObj }
                        response={ questionObj.response }
                        sectionIndex={ sectionIndex }
                    />
                );
            }
            return (
                <TextElement
                    key={ questionObj.question_id }
                    styleType={ questionObj.presentation_type || questionObj.display_type }
                    text={ questionObj.question_text }
                    sectionIndex={ sectionIndex }
                    handlePopup={ handlePopup }
                />
            );
    }
}

const GetElementFunc = (props) => {
    const { hide } = props;
    const klass = get(props, 'questionObj.className', '');
    if (klass) {
      return (
        <div data-name="get-element-wrapper" className={ klass }>
          {getElement(props)}
        </div>
      );
    }
    return (
      <div data-name="get-element-wrapper" style={ hide ? { display: 'none' } : {} }>
        {getElement(props)}
      </div>
    );
  };


const mapStateToProps = (state, props) => {
    const { questionObj } = props;
    const memberData = get(state, 'questions.otherParams.supplmentalresponse.member_data', []);
    const hide = questionObj.is_hidden || (questionObj.question_id === 'BeneficiaryPrefill' && memberData.length === 0);
    const popupQuestions = getPopupQuestions(state);
    return {
      popupQuestions,
      hide,
    }
}
  
const mapDispatchToProps = (dispatch) => {
  return {
    updatePopupDetails: (payload) => {
      dispatch(setPopupDetails(payload));
    },
  }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(GetElementFunc);

/* eslint-disable react/no-danger */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { TextInput, NumberInput, RangeSlider } from 'crux';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import './utils/styles.css';
import { updateAnswersArray } from '../redux/actions';
import {
  getLabelProps,
  getValidators,
  getWidth,
  getTooltipProps,
  getSliderLabels,
} from './utils';
import {
  QuestionDescription,
  QuestionText,
  InputWrapper,
  Wrapper,
} from './utils/styles';
import ActionButtons from '../Components/QuestionsLib/ActionButtons';
import { validateInnerHtml } from '../utils';
import { isEmpty } from 'lodash';

const Input = ({
  question,
  theme,
  handleChange,
  isEditMode,
  sectionIndex,
  prefillData,
  isPrefillValid,
  memberDataIndex,
  isQuestionsLoading,
}) => {
  const inputRef = useRef();

  const {
    pageId,
    config: {
      theme: {
        pageOverrides,
        components,
        global: {
          colorScheme,
          errorFontSize,
          errorMessageColor,
          spanText,
          inputStyles,
          labelStyles,
        },
      },
    },
  } = theme;

  const labelProps = getLabelProps('input', components, pageId, pageOverrides);

  const buttonLabelProps = getLabelProps(
    'button',
    components,
    pageId,
    pageOverrides
  );

  const inputProps = get(components, 'input', '');
  const { themeConfiguration } = inputProps;

  const {
    question_id: questionId,
    question_type: questionType,
    question_text: questionText,
    display_type: displayType,
    question_description: questionDescription,
    hint_title: tooltipTitle,
    hint_text: tooltipBody,
    hint_html: tooltipHTML,
    response,
    action,
    tooltip,
    isControlledInput,
    helper_text: helperText,
    restrict_input_value: restrictInputValue,
    allow_only_alphabets: allowOnlyAlphabets,
    is_editable: isEditable,
    is_readonly: isReadOnly,
  } = question;

  const tooltipProps = getTooltipProps(
    tooltipTitle,
    tooltipBody,
    tooltipHTML || tooltip
  );

  const validators = getValidators(question, isPrefillValid);

  // Setting the initial response for the field
  useEffect(() => {
    if (isEditMode || question.response) {
      handleChange(question.response, {}, true, false, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inputRef && inputRef.current && memberDataIndex !== null) {
      if (validators.autoFormat?.value) {
        inputRef.current.inputElement.value = prefillData;
      } else {
        inputRef.current.value = prefillData;
      }
      const questionId = inputRef.current.id;
      const hasError =
        questionId &&
        (questionId.includes('BeneStreetAddress') ||
          questionId.includes('BeneAddress2'))
          ? { error: !isPrefillValid }
          : {};
      handleChange(prefillData, hasError, true, false, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefillData, memberDataIndex]);

  const size = get(question, 'properties.size.value', '');

  const _handleChangeDebounce = debounce(handleChange, 100);

  const onChangeHandler = (
    value,
    hasError,
    preventReflexiveCall = true,
    ignoreChildQuestionsOn,
    shouldDebounce = true,
    isChangeEvent
  ) => {
    const args = [
      value,
      hasError,
      preventReflexiveCall,
      ignoreChildQuestionsOn,
      shouldDebounce,
      isChangeEvent,
    ];
    if (shouldDebounce && !isControlledInput) {
      _handleChangeDebounce(...args);
    } else {
      handleChange(...args);
    }
  };

  const handleBlur = (e) => {
    const { value, dataset } = e.target;
    const hasError = dataset.error === 'true';
    handleChange(
      value,
      {
        error: hasError,
      },
      hasError
    );
  };

  if (
    questionType === 'text' ||
    questionType === 'password' ||
    questionType === 'text_area'
  ) {
    return (
      <InputWrapper name="input-wrapper">
        <TextInput
          inputRef={inputRef}
          labelText={questionText}
          id={questionId}
          type={questionType}
          onChange={(value, err) => {
            onChangeHandler(value, err, false, false, false, true);
          }}
          onBlur={handleBlur}
          color={colorScheme}
          value={prefillData || response}
          displayType={displayType}
          isControlledInput={isControlledInput}
          {...labelProps}
          width={getWidth('input', pageId, pageOverrides, size)}
          {...validators}
          {...tooltipProps}
          errorMessageColor={errorMessageColor}
          errorFontSize={errorFontSize}
          helperText={helperText}
          allowOnlyAlphabets={allowOnlyAlphabets}
          inputStyles={inputStyles}
          spanText={spanText}
          labelStyles={labelStyles}
          themeConfiguration={themeConfiguration}
        />
        {action && (
          <ActionButtons
            label={action.label}
            actionProps={action}
            sectionIndex={sectionIndex}
            pageId={pageId}
            {...buttonLabelProps}
          />
        )}
      </InputWrapper>
    );
  }

  if (displayType === 'slider') {
    const minValue = parseInt(question.validations.min_value.value, 10);
    const maxValue = parseInt(question.validations.max_value.value, 10);
    const stepValue = parseInt(question.validations.step.value, 10)
    const incrementButtonConfig = {
      ...labelProps.rangeSlider.button.incrementButton,
      themeConfiguration: labelProps.rangeSlider.button.themeConfiguration,
    };
    const decrementButtonConfig = {
      ...labelProps.rangeSlider.button.decrementButton,
      themeConfiguration: labelProps.rangeSlider.button.themeConfiguration,
    };

    const getValidSliderValue = (value) => {
      if (value % stepValue === 0) return value;

      if (value < minValue) return minValue;

      let minLimit = minValue;
      let maxLimit = minValue + stepValue;

      while (maxLimit < maxValue) {
        if (minLimit < value && value < maxLimit) return maxLimit;
        minLimit = maxLimit;
        maxLimit = maxLimit + stepValue;
      }

      return maxValue;
    };

    return (
      <>
        {questionText && (
          <QuestionText>
            <span
              dangerouslySetInnerHTML={{
                __html: validateInnerHtml(questionText),
              }}
            />
          </QuestionText>
        )}
        {questionDescription && (
          <QuestionDescription>
            <span
              dangerouslySetInnerHTML={{
                __html: validateInnerHtml(questionDescription),
              }}
            />
          </QuestionDescription>
        )}
        <Wrapper name="range-slider-wrapper" {...labelProps.rangeSlider}>
          <RangeSlider
            onChange={(v) => {
              const sliderValue = getValidSliderValue(v);
              onChangeHandler(sliderValue, {}, false, true, false, true);
            }}
            defaultSelected={parseInt(response, 10)}
            prefix="$"
            showOnlyStepMultiples={
              labelProps.rangeSlider
                ? labelProps.rangeSlider.showOnlyStepMultiples
                : false
            }
            {...labelProps.rangeSlider}
            {...validators}
            incrementButton={incrementButtonConfig}
            decrementButton={decrementButtonConfig}
            minValue={minValue}
            maxValue={maxValue}
            step={stepValue}
            displayType={'contents'}
            donotShowValueOnSlider={false}
            sliderLabels={getSliderLabels(minValue, maxValue, stepValue, question.validations.marks)}
            isEditable={isEditable}
            isReadOnly={isReadOnly}
            isQuestionsLoading={isQuestionsLoading}
            markingArray={(() => {
              let marksArray = [];
              if(!question.validations.marks || !question.validations.marks.value) {
                return marksArray;
              }
              for (
                let index = minValue + question.validations.marks.value;
                index < maxValue;
                index += question.validations.marks.value
              ) {
                // 1000 can be variable based on marking difference
                marksArray.push(index);
              }
              return marksArray;
            })()}
          />
        </Wrapper>
      </>
    );
  }
  const allowedMaxValue = {
    value: 999,
  };

  const _labelStyles = {
    ...labelStyles,
    labelFontWeight: 'normal',
  };

  return (
    <div>
      <NumberInput
        labelText={questionText}
        id={questionId}
        onChange={(value, err) => {
          onChangeHandler(value, err, false, false, true);
        }}
        value={response}
        onBlur={handleBlur}
        color={colorScheme}
        {...labelProps}
        width={getWidth('input', pageId, pageOverrides, size)}
        {...validators}
        {...tooltipProps}
        errorMessageColor={errorMessageColor}
        errorFontSize={errorFontSize}
        restrictInputValue={restrictInputValue}
        allowedMaxValue={allowedMaxValue}
        inputStyles={inputStyles}
        spanText={spanText}
        labelStyles={_labelStyles}
        themeConfiguration={themeConfiguration}
      />
    </div>
  );
};

Input.defaultProps = {
  sectionIndex: -1,
};
Input.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  question: PropTypes.object.isRequired,
  /* eslint-disable react/no-unused-prop-types */
  sectionIndex: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
};

const _handleChange = (
  props,
  response,
  hasError,
  preventReflexiveCall,
  ignoreChildQuestionsOn,
  isChangeEvent,
  dispatch
) => {
  const questionId = get(props, 'question.question_id', '');
  dispatch(
    updateAnswersArray({
      questionId,
      response,
      sectionIndex: props.sectionIndex,
      preventReflexiveCall,
      isError: hasError.error,
      ignoreChildQuestionsOn,
      propQuestion: props.question,
      listParentId: props.listParentId,
      listIndex: props.listIndex,
      isChangeEvent,
    })
  );
};

const mapStateToProps = (state, props) => {
  const isQuestionsLoading = state.questions.isLoading;
  const questionId = get(props, 'question.question_id', '');
  const memberDataIndex = get(state, 'questions.memberDataIndex');
  const memberData = get(
    state,
    'questions.otherParams.supplmentalresponse.member_data',
    []
  );
  const addressData = get(
    state,
    'questions.otherParams.supplmentalresponse.address',
    {}
  );
  const mappedProps = {
    isQuestionsLoading,
    memberDataIndex,
  };
  if(!isEmpty(addressData)) {
       if((addressData.state).substring(0,11)=='MemberState') {
        const statevalue=(addressData.state);
        addressData.state=statevalue.slice(11,13);
         }  
  }
  if (memberData.length > parseInt(memberDataIndex, 10)) {
    const member = memberData[memberDataIndex];
    // const qId = questionId.toLowerCase();
    if (member) {
  
      switch(questionId) {
        case 'BeneFirstNameASGIWL':
          return  { 
                  prefillData: member.firstname.value,
                  isPrefillValid: member.firstname.valid,
                  ...mappedProps 
          };
        case 'BeneLastNameASGIWL':
          return { 
                  prefillData: member.lastname.value,
                  isPrefillValid: member.lastname.valid,
                  ...mappedProps 
          };
        case 'BeneFirstName':
          return  { 
                  prefillData: member.firstname.value,
                  isPrefillValid: member.firstname.valid,
                  ...mappedProps 
          };
        case 'BeneLastName' :
          return { 
                  prefillData: member.lastname.value,
                  isPrefillValid: member.lastname.valid,
                  ...mappedProps 
          };
        case 'BeneDateOfBirth':
          return{ prefillData: member.birthdate.value, ...mappedProps }; 
        case 'BeneMiddleName':
          return { prefillData: member.middlename.value, ...mappedProps }; 
        default:
            break;
      }
    }
  }

 
  if (Object.keys(addressData).length > 0 && memberDataIndex !== null) {
    switch (questionId) {
      case 'BeneStreetAddress':
        return {
          prefillData: addressData.addressline1.value,
          isPrefillValid: addressData.addressline1.valid,
          ...mappedProps,
        };
      case 'BeneAddress2':
        return {
          prefillData: addressData.addressline2.value,
          isPrefillValid: addressData.addressline2.valid,
          ...mappedProps,
        };
      case 'BeneCity':
        return { prefillData: addressData.city, ...mappedProps };
      case 'BeneZIPCode':
        return { prefillData: addressData.zipcode, ...mappedProps };
      default:
        break;
    }
  }
  return {
    prefillData: '',
    isPrefillValid: null,
    memberDataIndex: null,
    isQuestionsLoading,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleChange: (
      response,
      hasError,
      preventReflexiveCall,
      ignoreChildQuestionsOn,
      isChangeEvent
    ) => {
      _handleChange(
        props,
        response,
        hasError,
        preventReflexiveCall,
        ignoreChildQuestionsOn,
        isChangeEvent,
        dispatch
      );
    },
  };
};

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(Input));
